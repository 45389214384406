@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800,400i,600i');

body {
  font-family: $sans-font;
  font-size: 1rem;
}

.az-header {
  color: $bodyTextColor;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.42px;
}