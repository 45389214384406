@mixin breakpoint($point) {
  @if $point == phone {
		@media only screen and (max-width: 37.5em) { @content; } // 600px
	}

	@if $point == tab-port {
		@media only screen and (max-width: 56.25em) { @content; } // 900px
	}

	@if $point == tab-land {
		@media only screen and (max-width: 75em) { @content; } // 1200px
	}

	@if $point == big-desktop {
		@media only screen and (min-width: 112.5em) { @content; } // 1800px
  }
}


@mixin az-label($fontSize: 10px, $color: $labelTextColor, $letterSpacing: .75px) {
  color: $color;
  font-size: $fontSize;
  font-weight: 700;
  letter-spacing: $letterSpacing;
  text-transform: uppercase;
}