.form {

  &--single {
    /* single column forms */
    display: flex;
    flex-direction: column;
    
    & > * {
      width: 100%;
    }
  }

  &__link {
    color: $appzen-blue;
    cursor: pointer;
  }

  &__error {
    border: 1px solid $text-error;
    border-radius: 3px;
    color: $text-error;
    font-size: 0.8125rem;
    line-height: 1.5;
    margin: 0.625rem 0;
    padding: 0.625rem;
  }
  
}