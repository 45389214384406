.emulate-user {
  &-container {
    margin: 8% auto;
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
    width: 600px;
  }
  
}
