@import '../../../assets/css/abstract/variables';
@import '../../../assets/css/abstract/mixins';

.loginWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: 0px 20px 70px 20px;
    width: 85%;

    @include breakpoint(tab-port) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__logo {
    height: auto;
    margin-bottom: 32px;
    width: 140px;

    &-link {
      outline: none;
    }

    &-img {
      display: block;
      margin: 1.25rem auto;
      width: 100%;
    }
  }

  &__greetings-msg {
    color: grey4(6);
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: .27px;
  }

  &__messages {
    color: grey4(8);
    display: flex;
    flex-direction: column;
    font-size: 0.81rem;
    justify-content: center;
    letter-spacing: .2px;
    min-height: 6.25rem;
    padding-bottom: 0.63rem;
  }

  &__form {
    width: 100%;

    .form__insert {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 40px;

      .az-checkbox__label {
        font-size: 0.87rem;
        font-weight: 400;
      }
    }

    .form__link {
      color: blue3(7);
      font-size: 0.87rem;
      font-weight: normal;
      letter-spacing: .5px;
      text-decoration: none;
      transition: color .3s linear;

      &:hover {
        color: blue3(6);
      }
    }
  }

  &__button.button {
    font-size: 0.87rem;
    margin-bottom: 12px;
    min-width: 10rem;
    white-space: nowrap;
  }

  &__footer {
    color: $text-minor;
    font-size: 0.75rem;
    padding: 1.88rem 0;
  }

  &__footer-item {
    margin: 0 0.63rem;
  }
}
