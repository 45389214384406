@import '../../../assets/css/abstract/variables';

.marketing {
  &__content {
    max-width: 600px;
    width: 85%;
  }
  
  &__hero-img {
    display: block;
    height: auto;
    min-height: 1px;
    width: 100%;
  }

}