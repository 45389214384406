@import '../../assets/css/abstract/variables';
@import '../../assets/css/abstract/mixins';

.login {
  background-color: $white;
  color: grey4(6);
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.87rem;
  height: 100vh;
  min-height: 100vh;

  &__wrapper-col {
    align-items: center;
    background-color: $white;
    display: flex;
    flex-basis: 50%;
    justify-content: center;

    &--colored {
      background-color: blue2(1);
    }

    @include breakpoint(tab-port) {
      flex-basis: 100%;
    }
  }
}