@import '../../../../assets/css/abstract/variables';

.emulate {
  &-landing {
    color: $black;
    font-size: 0.87rem;
    margin-top: 60px;
    text-align: center;
  }


  &-success {
    &-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
    }

    &-header {
      font-family: $sans-font;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -1px;
      margin: 10px 0;
    }

    &__msg {
      margin-bottom: 1.25rem;
      margin-top: 3.13rem;
    }
  }
}

.green-check {
  margin-right: 0.63rem;

  &[role=presentation] {
    color: $baseGreen1;
  }
}

.horizontal-line {
  border: 0;
  border-top: 1px solid $lineBorderColor;
  height: 2px;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 50%;
}