@import '../../assets/css/abstract/variables';

.footer {
  align-items: center;
  background: grey1(5);
  color: grey4(6);
  display: flex;
  flex-wrap: nowrap;
  height: $siteFooterHeight;
  justify-content: space-between;
  padding: 0px 20px;
  position: static;
  width: 100%;

  &__text {
    color: grey4(6);
    font-size: 14px;
  }

}
