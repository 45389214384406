.l-container {
  max-width: 980px;
  width: 100%;
}

.l-full-container {
  width: 100%;
}

.l-flex-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row
}

.l-flex-col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column
}

.l-no-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.l-col-1-of-2 {
  width: 50%;
}

.l-col-3-of-5 {
  width: 60%;
}

.l-col-2-of-5 {
  width: 40%;
}