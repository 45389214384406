// colors
$white: #ffffff;
$black: #323232;
$appzen-blue: #147fb8;
$text-primary: #3892ca;
$text-minor: #bebebe;
$text-error: #e20303;
$button-primary: #1a97f0;
$grey: #ebebeb;
$yellow: #f0bf03;

$blue1: #2B5184;
$blue2: #3482C4;
$blue3: #1589EE;
$blue4: #43A0F1;
$blue5: #72B8F4;
$blue6: #A1CFF8;
$blue7: #D9EBFC;
$blue8: #ECF6FF;

$grey1: #12191D;
$grey2: #2D3F49;
$grey3: #57656D;
$grey4: #ABB2B6;
$grey5: #D6D9DB;
$grey6: #F4F7F9;
$grey7: #474544;

$green1: #04844B;
$green2: #61AE24;

$red1: #D0021B;
$red2: #C23934;

$orange1: #FF9100;

$yellow1: #EFC100;

$purple1: #9013FE;


// typography
$sans-font: 'Open Sans', Helvetica, Arial, sans-serif;

// dimension
$login-column-width: 23.75rem;
$siteFooterHeight: 52px;
$siteHeaderHeight: 55px;

// New branding color palettes
$baseBlue1: #CDF4FF;
$baseBlue2: #00A3E1;
$baseBlue3: #222BF9;
$baseBlue4: #252D53;

$baseGrey1: #F2F2F4;
$baseGrey2: #DCDCE5;
$baseGrey3: #8B8A8A;
$baseGrey4: #363A4C;

$basePurple1: #7A327E;
$basePurple2: #35196D;

$baseGreen1: #23DE9B;
$baseGreen2: #008247;

$baseOrange1: #F15A26;

$baseYellow1: #FBB03B;

$baseRed1: #D61600;
$basePink1: #DA1884;

@function create-palette($base-color) {
  $palette: ();

  $palette: append($palette, mix($base-color, white, 8%));
  $palette: append($palette, mix($base-color, white, 19%));
  $palette: append($palette, mix($base-color, white, 35%));
  $palette: append($palette, mix($base-color, white, 54%));
  $palette: append($palette, mix($base-color, white, 76%));
  $palette: append($palette, $base-color);
  $palette: append($palette, mix(black, $base-color, 15%));
  $palette: append($palette, mix(black, $base-color, 31%));
  $palette: append($palette, mix(black, $base-color, 45%));
  $palette: append($palette, mix(black, $base-color, 61%));

  @return $palette;
}

$blue1-palette: create-palette($baseBlue1);
$blue2-palette: create-palette($baseBlue2);
$blue3-palette: create-palette($baseBlue3);
$blue4-palette: create-palette($baseBlue4);
$grey1-palette: create-palette($baseGrey1);
$grey2-palette: create-palette($baseGrey2);
$grey3-palette: create-palette($baseGrey3);
$grey4-palette: create-palette($baseGrey4);
$purple1-palette: create-palette($basePurple1);
$purple2-palette: create-palette($basePurple2);
$green1-palette: create-palette($baseGreen1);
$green2-palette: create-palette($baseGreen2);
$orange1-palette: create-palette($baseOrange1);
$yellow1-palette: create-palette($baseYellow1);
$red1-palette: create-palette($baseRed1);
$pink1-palette: create-palette($basePink1);


@function blue1($index) {
  @return nth($blue1-palette, $index);
}

@function blue2($index) {
  @return nth($blue2-palette, $index);
}

@function blue3($index) {
  @return nth($blue3-palette, $index);
}

@function blue4($index) {
  @return nth($blue4-palette, $index);
}

@function grey1($index) {
  @return nth($grey1-palette, $index);
}

@function grey2($index) {
  @return nth($grey2-palette, $index);
}

@function grey3($index) {
  @return nth($grey3-palette, $index);
}

@function grey4($index) {
  @return nth($grey4-palette, $index);
}

@function purple1($index) {
  @return nth($purple1-palette, $index);
}

@function purple2($index) {
  @return nth($purple2-palette, $index);
}

@function green1($index) {
  @return nth($green1-palette, $index);
}

@function green2($index) {
  @return nth($green2-palette, $index);
}

@function orange1($index) {
  @return nth($orange1-palette, $index);
}

@function yellow1($index) {
  @return nth($yellow1-palette, $index);
}

@function red1($index) {
  @return nth($red1-palette, $index);
}

@function pink1($index) {
  @return nth($pink1-palette, $index);
}

/* Default colors */
$bodyBackground: grey1(2);
$bodyTextColor: grey4(8);
$disabledInputBackground: $grey5;
$disabledTextColor: grey4(3);
$highRiskColor: $red1;
$iconColor: grey4(5);
$labelTextColor: grey4(5);
$leftPanelBackgroundColor: grey2(4);
$lineHoverBackground: blue3(1);
$lineBorderColor: grey2(6);
$lineSelectedBackground: blue3(7);
$lowRiskColor: $green2;
$medRiskColor: $orange1;
