@import '../abstract/mixins';
@import '../abstract/variables';

.u {
  &-v-h-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &-h-space-between {
    justify-content: space-between !important;
  }

  &-h-center {
    text-align: center;
  }

  &-h-right {
    text-align: right;
  }

  &-hidden-tab-port {
    @include breakpoint(tab-port) {
      display: none !important;
    }
  }

  &-bold-text {
    font-weight: 700 !important;
  }

  &-brand-text {
    color: blue3(7);
  }

  &-high-risk {
    color: $highRiskColor !important;
  }

  &-link-blue {
    color: blue3(7) !important;

    &:hover {
      color: blue3(6) !important;
    }
  }

  &-low-risk {
    color: $lowRiskColor !important;
  }

  &-med-risk {
    color: $medRiskColor !important;
  }

  &-red-text {
    color: red1(6) !important;
  }

  &-text-grey-01 {
    color: $grey1 !important;
  }

  &-text-grey-02 {
    color: $grey2 !important;
  }

  &-text-grey-03 {
    color: $grey3 !important;
  }

  &-text-grey-04 {
    color: $grey4 !important;
  }

  &-position-relative {
    position: relative;
  }
}
