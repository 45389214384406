@import '../../../assets/css/abstract/variables';

.support-dashboard {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 8% auto;
    align-items: flex-start;
  }

  &__tile {
    background-color: $white;
    border: 1px solid $lineBorderColor;
    border-radius: 4px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 275px;
    justify-content: center;
    align-items: center;
    margin: 20px;
    transition: all 0.2s ease-in;
    width: 275px;
    text-align: center;

    &:hover{
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
      transform: scale(1.03);

      .support-dashboard-tile__icon {
        color: $baseBlue3;
      }
    }
  }

  &-tile {
    &__icon {
      color: $baseGrey3;
      transition: all 0.2s ease-in;
      &[role=presentation] {
        font-size: 75px;
      }
    }

    &__text {
      color: $baseGrey4;
      font-size: 14px;
      letter-spacing: 0.25px;
      margin-top: 20px;
      padding: 0 25%;
      text-align: center;
      width: 100%;
    }
  }
}



