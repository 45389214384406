.support-card-header {
  margin: 0 auto;
  
  &__title {
    font-size: 14px;
    letter-spacing: 0.25px;
    margin-bottom: 12px;
    text-align: center;
  }

  &__icon {
    opacity: 0.7;
    text-align: center;
    &[role=presentation] {
      font-size: 30px;
    }
  }
}