*, *::before, *::after {
  box-sizing: inherit;
}

html {
  font-size: 100%; /* 1rem = 16px */
  min-height: 100%;
}

body {
  box-sizing: border-box;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

#root {
  min-height: 100%;
}

@media print {
  body, html {
    overflow: visible;
  }
}
