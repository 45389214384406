.az-modal {

  &__header {
    position: relative;

    &--centered {
      text-align: center;
    }
  }

  &__heading {
    font-size: 18px;
    font-weight: 600;
  }
  
  &__close {
    cursor: pointer;
    fill: grey2(7);
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all .3s linear;

    &:hover {
      fill: grey2(9);
    }
  }

  &__body {
    &--centered {
      text-align: center;
    }
  }

  &__button {
    font-size: 13px;
    letter-spacing: .5px;
  }

}