@import '../../../../assets/css/abstract/variables';

.support-card{
    background: $white;
    border: 0.5px solid $lineBorderColor;
    border-radius: 5px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
    height: 340px;
    margin: 0 auto;
    padding: 40px 100px;
    width: 600px;
}