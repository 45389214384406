@import '../../assets/css/abstract/variables';

.ribbon {
  display: flex;
  align-items: center;
  min-height: 40px;
  background: #474544;
  padding: 0 13px;
  position: relative;

  &__icon {
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    vertical-align: middle;
    color: $white;
    font-size: 13px;
    background-color: #707070;
    padding: 0;
  }
}
